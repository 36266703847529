import {
  BarChartLabel,
  BarChartText,
  GridWithGaps,
  HeadingH2,
} from '@dreamstack/feature-components'
import { useFormatToDecimal, useLocale } from '@dreamstack/i18n'
import type { BarChartWithBackground } from '@dreamstack/investors-graphql'
import { useAnimationOnScreen } from '@dreamstack/util'
import { motion } from 'framer-motion'
import map from 'lodash/map'
import max from 'lodash/max'
import type { FunctionComponent} from 'react';
import { useCallback, useMemo } from 'react'
import tw, { styled } from 'twin.macro'

interface BarChartEntry {
  x: string
  y: number
}

// TODO: Make video changable in contentfull?
const videoUrl = '/static/videos/acc_graph_footage_minified.mp4'

const ChartBackgroundImage = styled(motion.div)(() => [
  // `background-image: url(${bgUrl});`,
  tw`relative overflow-hidden`,
  tw`col-span-full`,
  tw`height[278px] mt-px-20 bg-cover bg-center md:height[438px] lg:height[534px]`,
])

const ChartWhiteLayer = styled.div(() => [
  `mix-blend-mode: hard-light;`,
  tw`w-full h-full flex space-x-px-16 md:space-x-px-48 lg:space-x-px-80 bg-white items-end`,
])

const BarWrapper = tw.div`h-full flex flex-col text-center`
const Bar = styled.div(() => [
  tw`h-full pt-px-12 md:pt-px-40  px-px-8`,
  `mix-blend-mode: darken;`,
  `background: linear-gradient(to top,#888 0%,#333 100%)`,
])

const ChartHeading = tw(HeadingH2)`col-span-full`

const Video = () => (
  <video
    loop
    autoPlay
    playsInline
    muted
    width="100%"
    height="100%"
    tw="object-cover h-full absolute"
  >
    <source src={videoUrl} type={'video/mp4'} />
  </video>
)

const calcPercentualHeight = (
  amount: number,
  highestAmount: number
): string => {
  return `${(amount / highestAmount) * 100}%`
}

const Grid = styled(GridWithGaps)<{ noOfBars: number }>(({ noOfBars }) => [
  tw`col-span-full`,
  `grid-template-columns: repeat(${noOfBars}, 1fr);`,
])

const staggerWrapperVariants = {
  visible: {
    transition: {
      staggerChildren: 0.2,
    },
  },
}

const staggerElementVariants = {
  visible: { y: 0, transition: { type: 'linear', duration: 0.8 } },
  hidden: { y: 500 },
}

export const BarChartWithBackgroundBlock: FunctionComponent<React.PropsWithChildren<{
  block: BarChartWithBackground
}>> = ({ block }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const bars: BarChartEntry[] = block.bars?.entries || []
  const formatNumber = useFormatToDecimal({ fractionDigits: 1 })
  const locale = useLocale()

  const { controls, ref } = useAnimationOnScreen()

  const formatAmount = useCallback(
    (amount: number): string => {
      let formatted = (amount / 1_000_000).toFixed(1)
      formatted = formatNumber(formatted)
      const suffix = locale === 'de' ? ' Mio. €' : 'm euro'
      const localized = `${formatted}${suffix}`
      return localized
    },
    [formatNumber, locale]
  )

  const barsMemo = useMemo(() => {
    const highestAmount: number = max(map(bars, (bar) => bar.y)) ?? 1
    const width = `${100 / bars.length}%`
    return map(bars, (bar, index) => {
      const height = calcPercentualHeight(bar.y, highestAmount)
      const formattedAmount = formatAmount(bar.y)
      return (
        <motion.div
          key={index}
          variants={staggerElementVariants}
          style={{ height, width, minHeight: '120px' }}
        >
          <BarWrapper>
            <Bar>
              <BarChartText tw="font-semibold text-accentroWhite-full">
                {formattedAmount}
              </BarChartText>
            </Bar>
          </BarWrapper>
        </motion.div>
      )
    })
  }, [bars, formatAmount])
  return (
    <>
      <GridWithGaps tw="pb-px-48 md:pb-px-64">
        <Grid noOfBars={bars.length}>
          <ChartHeading>{block.title}</ChartHeading>
          <ChartBackgroundImage
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={staggerWrapperVariants}
          >
            <Video />
            <ChartWhiteLayer>{barsMemo}</ChartWhiteLayer>
          </ChartBackgroundImage>
          {map(bars, (bar, index) => (
            <BarChartLabel
              key={index}
              tw="font-semibold mt-px-12 md:mt-px-24 justify-self-center"
            >
              {bar.x}
            </BarChartLabel>
          ))}
        </Grid>
      </GridWithGaps>
    </>
  )
}
